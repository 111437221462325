import { Link, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import SigninScreen from "../screens/SigninScreen";
import { LanguageContext } from "../contexts/LanguageContext";
import { englishLanguage } from "../contexts/englishContext";
import { useEffect, useState } from "react";
import { portugueseLanguage } from "../contexts/portugueseContext";
import Navbar from "../components/admin/Navbar";

const AdminRoot = () => {
  const { currentUser, signout } = useAuth();
  const location = useLocation();
  const [language, setLanguage] = useState<ILanguage>(englishLanguage);
  const [languageId, setLanguageId] = useState("english");

  useEffect(() => {
    switch (languageId) {
      case "english":
        setLanguage(englishLanguage);
        break;
      case "portuguese":
        setLanguage(portugueseLanguage);
        break;
      default:
        setLanguage(englishLanguage);
        break;
    }
  }, [languageId]);

  return (
    <LanguageContext.Provider value={language}>
      <div className="flex min-h-screen flex-col bg-slate-500">
        <Navbar setLanguageId={setLanguageId} />

        {/* Content */}
        <div className="flex flex-1 flex-col">
          {currentUser ? <Outlet /> : <SigninScreen />}
        </div>
      </div>
    </LanguageContext.Provider>
  );
};

export default AdminRoot;
