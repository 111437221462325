import React, { useState, useContext } from "react";
import { LanguageContext } from "../../contexts/LanguageContext";
import { FaCalendarCheck, FaMapMarkerAlt } from "react-icons/fa";
import { TbTruckDelivery } from "react-icons/tb";
import { BiMoney } from "react-icons/bi";

interface DeliveryProps {
  delivery: IDelivery;
}

const Delivery = ({ delivery }: DeliveryProps) => {
  const language = useContext(LanguageContext).deliveriesPage;
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div
      onClick={() => setShowInfo((prev) => !prev)}
      className={`mb-2 cursor-pointer rounded border p-1 drop-shadow-sm sm:p-3 ${
        delivery.alreadyDelivered ? "bg-green-100" : "bg-white"
      }`}
    >
      <div className="mb-2 flex flex-wrap">
        <span
          className={`${
            delivery.alreadyDelivered ? "bg-green-500" : "bg-red-500"
          } mr-2 flex items-center rounded px-1 font-bold text-white`}
        >
          <TbTruckDelivery className="mr-1" />
          <span>
            {delivery.alreadyDelivered
              ? language.deliveredTag
              : language.notDeliveredTag}
          </span>
        </span>
        <span
          className={`${
            delivery.alreadyPaid ? "bg-green-500" : "bg-red-500"
          } flex items-center rounded px-1 font-bold text-white`}
        >
          <BiMoney className="mr-1" />
          <span>
            {delivery.alreadyPaid ? language.paidTag : language.notPaidTag}
          </span>
        </span>
      </div>

      <div className="text-lg2 flex flex-wrap p-1">
        <span className="mr-2 flex items-center">
          <FaCalendarCheck className="mr-1" />{" "}
          {new Date(delivery.deliveryDate.toDate()).toLocaleString()}
        </span>

        <span className="mr-2 flex items-center">
          <FaMapMarkerAlt className="mr-1" /> {delivery.address}
        </span>
      </div>

      {showInfo && (
        <div className="mt-3 bg-white p-1">
          <div className="mb-3 p-2">
            <p className="mb-2 text-center text-lg">{language.items}</p>
            <div className="grid grid-cols-3">
              <span className="font-bold">
                {language.product} ({delivery.orderItems.length})
              </span>
              <span className="font-bold">{language.quantity}</span>
              <span className="font-bold">{language.price}</span>
              {delivery.orderItems.map((item, idx) => (
                <React.Fragment key={idx}>
                  <span>{item.productName}</span>
                  <span>{item.quantity}</span>
                  <span>{item.productPrice}</span>
                </React.Fragment>
              ))}
            </div>
          </div>
          <hr />
          <ul className="mt-3 p-2">
            <li>
              <b>{language.deliveryAddress}:</b> {delivery.address}
            </li>
            <li>
              <b>{language.clientName}:</b> {delivery.clientName}
            </li>
            <li>
              <b>{language.clientPhone}:</b> {delivery.clientPhone}
            </li>
            <li>
              <b>{language.orderDelivered}</b>{" "}
              {delivery.alreadyDelivered
                ? language.orderDeliveredYes
                : language.orderDeliveredNo}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Delivery;
