import React, { useContext } from "react";
import { LanguageContext } from "../../contexts/LanguageContext";
import { FaMapMarkerAlt, FaMoneyBillWave, FaPhoneVolume } from "react-icons/fa";
import { MdDeliveryDining } from "react-icons/md";
import { calculateOrderTotal } from "../../utils";

interface OrderMapCardProps {
  displayOrder: boolean;
  orderData: IDelivery | null;
}

const OrderMapCard: React.FC<OrderMapCardProps> = ({
  displayOrder,
  orderData,
}) => {
  const language = useContext(LanguageContext).deliveryMapPage;
  return (
    <div
      className={`absolute top-4 z-50 w-[calc(100%-8px)] rounded border border-slate-500 bg-white p-4 transition-all duration-1000 ease-in-out sm:w-auto ${
        displayOrder ? "left-1 sm:left-4" : "-left-[600px]"
      }`}
    >
      <div className="flex flex-wrap items-center gap-y-1">
        {orderData?.alreadyPaid ? (
          <span className="delivery-map__order-card-button cursor-pointer bg-green-600">
            <FaMoneyBillWave className="mr-1" />
            <span>{language.paidTag}</span>
          </span>
        ) : (
          <span className="delivery-map__order-card-button cursor-pointer bg-red-600">
            <FaMoneyBillWave className="mr-1" />
            <span>{language.notPaidTag}</span>
          </span>
        )}

        {orderData?.alreadyDelivered ? (
          <span className="delivery-map__order-card-button cursor-pointer bg-green-600">
            <MdDeliveryDining className="mr-1" />
            <span>{language.deliveredTag}</span>
          </span>
        ) : (
          <span className="delivery-map__order-card-button cursor-pointer bg-red-600">
            <MdDeliveryDining className="mr-1" />
            <span>{language.notDeliveredTag}</span>
          </span>
        )}

        <span
          className="delivery-map__order-card-button cursor-pointer bg-slate-600  "
          onClick={() => window.open(`tel:${orderData?.clientPhone}`)}
        >
          <FaPhoneVolume className="mr-1" />
          <span>{language.callClientButton}</span>
        </span>
      </div>

      <h1 className="my-5 flex items-center text-lg">
        <FaMapMarkerAlt className="mr-1" />
        <span>{orderData?.address}</span>
      </h1>

      <table className="my-5 table-fixed border-separate border-spacing-1 text-left">
        <thead>
          <tr className="[&_th]:pr-5">
            <th>{language.product}</th>
            <th>{language.price}</th>
            <th>{language.quantity}</th>
            <th>{language.subtotal}</th>
          </tr>
        </thead>
        <tbody>
          {orderData?.orderItems.map((item, idx) => (
            <tr key={idx}>
              <td>{item.productName}</td>
              <td>$ {item.productPrice}</td>
              <td>{item.quantity} x</td>
              <td>$ {item.quantity * item.productPrice}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <b>{language.total}</b>
            </td>
            <td></td>
            <td></td>
            <td className="pt-3 text-lg font-bold">
              $ {orderData && calculateOrderTotal(orderData.orderItems)}
            </td>
          </tr>
        </tfoot>
      </table>

      <ul>
        <li>
          <b>{language.client}:</b> {orderData?.clientName}
        </li>
        <li>
          <b>{language.phone}:</b> {orderData?.clientPhone}
        </li>
        <li>
          <b>{language.details}:</b> {orderData?.details}
        </li>
      </ul>
    </div>
  );
};

export default OrderMapCard;
