// Libraries
import React, { useEffect, useState, useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

// Components
import CreateProduct from "../components/admin/CreateProduct";
import ListOfProducts from "../components/admin/ListOfProducts";
import { Heading } from "@aprendaagora/simple-react-component-library";

const ProductsScreen = () => {
  const language = useContext(LanguageContext).productsPage;
  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    function onResult(querySnapshot: any) {
      const products: IProduct[] = [];

      querySnapshot.forEach(function (doc: any) {
        const tempData: IProduct = { id: doc.id, data: doc.data() };
        products.push(tempData);
      });

      setProducts(products);
    }

    function onError(error: any) {
      console.log(error);
    }

    const unsubscribe = onSnapshot(
      collection(db, "products"),
      onResult,
      onError
    );
    return unsubscribe;
  }, []);

  return (
    <div className="mx-2 mt-4 sm:mx-auto sm:w-[600px]">
      <Heading
        className="mb-5 w-fit bg-white px-1"
        text={language.heading}
        level={3}
      />
      <CreateProduct />
      <ListOfProducts products={products} />
    </div>
  );
};

export default ProductsScreen;
