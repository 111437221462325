import React, { FC, useRef, useState, useContext } from "react";
import { LanguageContext } from "../../contexts/LanguageContext";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import {
  Heading,
  FormGroup,
  Button,
} from "@aprendaagora/simple-react-component-library";

const CreateProduct: FC = () => {
  const language = useContext(LanguageContext).productsPage;
  const [showForm, setShowForm] = useState(false);
  const [newProductName, setNewProductName] = useState<string>("");
  const [newProductDescription, setNewProductDescription] =
    useState<string>("");
  const [newProductImage, setNewProductImage] = useState<string>("");
  const [newProductPrice, setNewProductPrice] = useState<number>(0);
  const priceInput = useRef<HTMLInputElement>(null);

  const handleCreateNewProduct = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    await addDoc(collection(db, "products"), {
      name: newProductName,
      price: newProductPrice,
      description: newProductDescription,
      image: newProductImage,
    })
      .then(() => {
        alert(language.productCreatedAlert(newProductName));
      })
      .catch((error) => alert("Error: " + error))
      .finally(() => {
        setNewProductName("");
        setNewProductDescription("");
        setNewProductPrice(0);
        priceInput.current!.value = "0";
        setNewProductImage("");
      });
  };

  return (
    <div className="mx-1 my-2 border bg-white p-3 transition-all duration-150">
      <div
        onClick={() => setShowForm((prev) => !prev)}
        className="flex cursor-pointer items-center justify-between"
      >
        <Heading text={language.createProduct} level={6} />
        <div className="h-fit rounded bg-slate-300 p-1">
          {showForm ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>

      {showForm && (
        <form onSubmit={handleCreateNewProduct} className={`mt-3 bg-white p-2`}>
          <FormGroup
            value={newProductName}
            onChange={setNewProductName}
            label={language.newProductName}
            name="newProductName"
            className="mb-3"
          />

          <FormGroup
            value={newProductDescription}
            onChange={setNewProductDescription}
            label={language.newProductDescription}
            name="newProductDescription"
            className="mb-3"
          />

          <div className="mb-2 flex flex-col">
            <label htmlFor="newProductPrice">{language.newProductPrice}</label>
            <input
              onChange={(e) => setNewProductPrice(Number(e.target.value))}
              ref={priceInput}
              type="number"
              step="0.01"
              name="newProductPrice"
              id="newProductPrice"
              className="rounded border p-1"
            />
          </div>

          <FormGroup
            value={newProductImage}
            onChange={setNewProductImage}
            label={language.newProductImage}
            name="newProductImage"
            className="mb-3"
          />

          <Button
            className="rounded p-1"
            type="primary"
            text={language.createProductButton}
          />
        </form>
      )}
    </div>
  );
};

export default CreateProduct;
