import React, { useEffect, useLayoutEffect, useState, useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import CreateOrder from "../components/admin/CreateOrder";
import Order from "../components/admin/Order";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { db } from "../firebase";
import { Heading } from "@aprendaagora/simple-react-component-library";

const OrdersScreen = () => {
  const language = useContext(LanguageContext).ordersPage;
  const [orders, setOrders] = useState<IOrderFirebase[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);

  useLayoutEffect(() => {
    function onResult(querySnapshot: any) {
      const orders: IOrderFirebase[] = [];
      querySnapshot.forEach(function (doc: any) {
        const tempData: IOrderFirebase = { id: doc.id, data: doc.data() };
        orders.push(tempData);
      });
      setOrders(orders);
    }

    function onError(error: any) {
      console.log(error);
    }

    const q = query(collection(db, "orders"), orderBy("date", "desc"));
    const unsubscribe = onSnapshot(q, onResult, onError);
    return unsubscribe;
  }, []);

  useEffect(() => {
    function onResult(querySnapshot: any) {
      const products: IProduct[] = [];
      querySnapshot.forEach(function (doc: any) {
        const tempData: IProduct = { id: doc.id, data: doc.data() };
        products.push(tempData);
      });
      setProducts(products);
    }

    function onError(error: any) {
      console.log(error);
    }

    const unsubscribe = onSnapshot(
      collection(db, "products"),
      onResult,
      onError
    );
    return unsubscribe;
  }, []);

  return (
    <div className="mx-2 mt-4 sm:mx-auto sm:w-[600px]">
      <Heading
        text={language.heading}
        level={3}
        className="mb-5 w-fit bg-white px-1"
      />

      <CreateOrder products={products} />

      <div className="mx-1 mt-5 border bg-white p-3">
        <Heading text={language.listOfOrders} level={6} className="mb-3" />
        {orders.length ? (
          orders.map((order) => {
            return <Order key={order.id} order={order.data} />;
          })
        ) : (
          <p className="col-span-3">{language.thereAreNoOrders}</p>
        )}
      </div>
    </div>
  );
};

export default OrdersScreen;
