import React, { useState } from "react";
import DeliveryMap from "../components/admin/DeliveryMap";
import OrderMapCard from "../components/admin/OrderMapCard";

const DeliveryMapScreen = () => {
  const [displayOrder, setDisplayOrder] = useState(false);
  const [orderData, setOrderData] = useState<IDelivery | null>(null);

  return (
    <div className="relative h-full w-full flex-1 overflow-hidden bg-orange-500">
      <OrderMapCard displayOrder={displayOrder} orderData={orderData} />
      <DeliveryMap
        setDisplayOrder={setDisplayOrder}
        setOrderData={setOrderData}
      />
    </div>
  );
};

export default DeliveryMapScreen;
