import { Heading } from "@aprendaagora/simple-react-component-library";
import React, { FC, useContext } from "react";
import { LanguageContext } from "../../contexts/LanguageContext";
import Product from "./Product";

interface ListOfProductsProps {
  products: IProduct[];
}

const ListOfProducts: FC<ListOfProductsProps> = ({
  products,
}: /*deleteProduct,*/
ListOfProductsProps) => {
  const language = useContext(LanguageContext).productsPage;
  return (
    <div className="mx-1 my-2 border bg-white p-3">
      <Heading text={language.heading} level={6} />

      <p>
        {language.numberOfProducts}: {products.length}
      </p>
      {products.length ? (
        <table className="w-full border-separate border-spacing-y-2">
          <thead>
            <tr>
              <th>{language.productImage}</th>
              <th>{language.productName}</th>
              <th>{language.productPrice} ($)</th>
              <th>{language.productOptions}</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <Product key={product.id} product={product} />
            ))}
          </tbody>
        </table>
      ) : (
        <p className="col-span-3">{language.thereAreNoProducts}</p>
      )}
    </div>
  );
};

export default ListOfProducts;
