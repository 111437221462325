// Libraries
import React, { useLayoutEffect, useState, useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { db } from "../firebase";
import Delivery from "../components/admin/Delivery";
import { Heading } from "@aprendaagora/simple-react-component-library";

const DeliveriesScreen = () => {
  const language = useContext(LanguageContext).deliveriesPage;
  const [deliveries, setDeliveries] = useState<IDeliveryWithID[]>([]);

  // Gets deliveries data
  useLayoutEffect(() => {
    const onResult = (querySnapshot: any) => {
      const deliveries: IDeliveryWithID[] = [];

      querySnapshot.forEach((doc: IDeliveryFirestore) => {
        const tempData: IDeliveryWithID = { id: doc.id, data: doc.data() };
        deliveries.push(tempData);
      });

      console.log("deliveries >>>> ", deliveries);
      setDeliveries(deliveries);
    };

    function onError(error: any) {
      console.log(error);
    }

    const q = query(
      collection(db, "deliveries"),
      orderBy("deliveryDate", "desc")
    );

    const unsubscribe = onSnapshot(q, onResult, onError);

    return unsubscribe;
  }, []);

  return (
    <div className="mx-2 mt-4 sm:mx-auto sm:w-[600px]">
      <Heading
        text={language.heading}
        level={3}
        className="mb-5 w-fit bg-white px-1"
      />

      {deliveries.length ? (
        deliveries.map((delivery) => (
          <Delivery key={delivery.id} delivery={delivery.data} />
        ))
      ) : (
        <p className="col-span-3 bg-white p-5">
          {language.thereAreNoDeliveries}
        </p>
      )}
    </div>
  );
};

export default DeliveriesScreen;
