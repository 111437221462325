import React, { useLayoutEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { db } from "../../firebase";

const mapStyles = [
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

const containerStyle = {
  width: "100%",
  height: "100%",
  disableDefaultUI: false,
};

const center = {
  lat: -20.17460708161137,
  lng: -40.206837047660386,
};

const markerCoordinates = {
  lat: -20.173841808395544,
  lng: -40.20386185207933,
};

const googleMapsOptions = {
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  styles: mapStyles,
  minZoom: 8,
  maxZoom: 20,
};

interface DeliveryMapProps {
  setDisplayOrder: React.Dispatch<React.SetStateAction<boolean>>;
  setOrderData: React.Dispatch<React.SetStateAction<IDelivery | null>>;
}

function DeliveryMap({ setDisplayOrder, setOrderData }: DeliveryMapProps) {
  const [deliveries, setDeliveries] = useState<IDeliveryWithID[]>([]);
  // Gets deliveries data
  useLayoutEffect(() => {
    const onResult = (querySnapshot: any) => {
      const deliveries: IDeliveryWithID[] = [];

      querySnapshot.forEach((doc: IDeliveryFirestore) => {
        const tempData: IDeliveryWithID = { id: doc.id, data: doc.data() };
        deliveries.push(tempData);
      });

      setDeliveries(deliveries);
    };

    function onError(error: any) {
      console.log(error);
    }

    const q = query(
      collection(db, "deliveries"),
      orderBy("deliveryDate", "desc")
    );

    const unsubscribe = onSnapshot(q, onResult, onError);

    return unsubscribe;
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB4Uoz1ym5Cnq1_To_fDkiYOvQ2frFg7_4",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    map.setZoom(15);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const handleDisplayOrder = (data: IDelivery) => {
    setDisplayOrder(true);
    setOrderData(data);
  };

  return isLoaded ? (
    <div className="absolute h-full w-full">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        options={googleMapsOptions}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={() => setDisplayOrder(false)}
      >
        {/* Child components, such as markers, info windows, etc. */}
        {deliveries.map((delivery, idx) => (
          <Marker
            icon={{
              url: delivery.data.alreadyDelivered
                ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
                : "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
            }}
            key={idx}
            onClick={() => handleDisplayOrder(delivery.data)}
            position={{
              lat: delivery.data.coordinates.latitude,
              lng: delivery.data.coordinates.longitude,
            }}
          />
        ))}
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}

export default React.memo(DeliveryMap);
