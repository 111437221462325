import React, { FC, useState, useContext } from "react";
import { LanguageContext } from "../../contexts/LanguageContext";
import { FaPen, FaSave } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import placeholder from "../../assets/images/placeholder.png";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

interface ProductProps {
  product: IProduct;
}

const Product: FC<ProductProps> = ({ product }: ProductProps) => {
  const language = useContext(LanguageContext).productsPage;
  const [newName, setNewName] = useState(product.data.name);
  const [newPrice, setNewPrice] = useState(product.data.price);
  const [inputsEnabled, setInputsEnabled] = useState<boolean>(false);

  const handleCancelEditing = () => {
    setNewName(product.data.name);
    setNewPrice(product.data.price);
    setInputsEnabled(false);
  };

  const saveEdit = async () => {
    const productRef = doc(db, "products", product.id);
    await updateDoc(productRef, {
      name: newName,
      price: newPrice,
    });
    setInputsEnabled(false);
    window.alert(language.productEditedAlert);
  };

  return (
    <tr className="[&_td]:bg-gray-100 [&_td]:p-1">
      <td className="w-12 rounded-l align-top sm:w-16">
        <img
          className="mx-auto w-12 sm:w-16"
          src={product.data.image ? product.data.image : placeholder}
          alt=""
        />
      </td>

      <td className="align-top">
        <input
          disabled={!inputsEnabled}
          onChange={(e) => setNewName(e.target.value)}
          value={newName}
          type="text"
          className={`h-full w-full min-w-fit p-[6px] text-black ${
            inputsEnabled ? "rounded bg-white" : "bg-transparent"
          }`}
        />
      </td>

      <td className="w-[8ch] align-top">
        <input
          disabled={!inputsEnabled}
          value={newPrice}
          onChange={(e) => setNewPrice(Number(e.target.value))}
          step="0.01"
          type="number"
          name=""
          id=""
          className={`mx-auto w-[8ch] p-1 ${
            inputsEnabled ? "rounded bg-white" : "bg-transparent"
          }`}
        />
      </td>

      <td className="w-16 rounded-r align-top sm:w-20">
        <div className="mx-auto flex w-16 flex-wrap sm:w-20 [&>span]:flex [&>span]:h-fit [&>span]:cursor-pointer [&>span]:items-center [&>span]:rounded [&>span]:border [&>span]:border-white [&>span]:p-1 [&>span]:text-xs [&>span]:font-bold [&>span]:text-white sm:[&>span]:text-sm">
          {inputsEnabled ? (
            <>
              <span className="mb-1 bg-green-600" onClick={() => saveEdit()}>
                <FaSave className="mr-1" /> {language.saveButton}
              </span>

              <span
                className="bg-gray-600"
                onClick={() => handleCancelEditing()}
              >
                <MdCancel className="mr-1" /> {language.cancelButton}
              </span>
            </>
          ) : (
            <>
              <span
                className="bg-blue-600"
                onClick={() => setInputsEnabled(true)}
              >
                <FaPen className="mr-1" /> {language.editButton}
              </span>
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

export default Product;
