import { Link, useLocation } from "react-router-dom";
import { GrNotes } from "react-icons/gr";
import { FaList, FaMapMarkedAlt } from "react-icons/fa";
import { MdDeliveryDining } from "react-icons/md";
import logo from "../../assets/images/padaria-logo.png";
import { IoLanguage } from "react-icons/io5";
import { Button } from "@aprendaagora/simple-react-component-library";
import { useAuth } from "../../contexts/AuthContext";
import { LanguageContext } from "../../contexts/LanguageContext";
import { useContext } from "react";

interface NavbarProps {
  setLanguageId: React.Dispatch<React.SetStateAction<string>>;
}

const Navbar: React.FC<NavbarProps> = ({ setLanguageId }) => {
  const language = useContext(LanguageContext).navbar;
  const { currentUser, signout } = useAuth();
  const location = useLocation();

  return (
    <div className="flex flex-wrap items-center gap-2 border-b-2 border-slate-700 bg-slate-600 p-2 ">
      <Link to="/admin">
        <div className="mr-3 h-12 w-12 rounded-full bg-white p-1">
          <img src={logo} alt="logo" />
        </div>
      </Link>

      {currentUser && (
        <>
          <Link
            to="/admin/products"
            className={`navbar__item ${
              location.pathname.includes("products") && "!bg-white"
            }`}
          >
            <FaList />
            <span>{language.products}</span>
          </Link>

          <Link
            to="/admin/orders"
            className={`navbar__item ${
              location.pathname.includes("orders") && "!bg-white"
            }`}
          >
            <GrNotes /> <span>{language.orders}</span>
          </Link>

          <Link
            to="/admin/deliveries"
            className={`navbar__item ${
              location.pathname.includes("deliveries") && "!bg-white"
            }`}
          >
            <MdDeliveryDining /> <span>{language.deliveries}</span>
          </Link>

          <Link
            to="/admin/deliverymap"
            className={`navbar__item ${
              location.pathname.includes("deliverymap") && "!bg-white"
            }`}
          >
            <FaMapMarkedAlt /> <span>{language.deliverymap}</span>
          </Link>
        </>
      )}
      <div className="flex items-center bg-white">
        <IoLanguage className="mx-1" color="black" />
        <select className="p-2" onChange={(e) => setLanguageId(e.target.value)}>
          <option value="english">English</option>
          <option value="portuguese">Português</option>
        </select>
      </div>

      {currentUser && (
        <Button
          text={language.signout}
          type="danger"
          className="rounded border border-red-600 p-1"
          onClick={signout}
        />
      )}
    </div>
  );
};

export default Navbar;
