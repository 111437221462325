export const portugueseLanguage: ILanguage = {
  navbar: {
    products: "Produtos",
    orders: "Pedidos",
    deliveries: "Entregas",
    deliverymap: "Mapa de Entregas",
    signout: "Sair",
  },
  productsPage: {
    heading: "Produtos",
    createProduct: "Criar Produto",
    newProductName: "Nome do Produto",
    newProductDescription: "Descrição do Produto",
    newProductPrice: "Preço do Produto",
    newProductImage: "URL da Imagem do Produto",
    createProductButton: "Criar Produto",
    productsList: "Lista de Produtos",
    numberOfProducts: "Número de Produtos",
    productImage: "Imagem",
    productName: "Nome",
    productPrice: "Preço",
    productOptions: "Opções",
    editButton: "Editar",
    saveButton: "Salvar",
    cancelButton: "Cancelar",
    thereAreNoProducts: "Não há nenhum produto registrados.",
    productCreatedAlert: (product: string) => `Produto ${product} criado.`,
    productEditedAlert: "A informação do produto foi salva.",
  },
  ordersPage: {
    heading: "Pedidos",
    createOrder: "Criar Pedido",
    chooseAProduct: "Escolha um produto",
    orderItems: "Itens do Pedido",
    forDeliveryButton: "Para Entrega",
    deliveryDetails: "Detalhes da Entrega",
    clientName: "Nome do Cliente",
    clientPhone: "Telefone do Cliente",
    deliveryAddress: "Endereço da Entrega",
    orderAlreadyPaid: "Pedido já pago",
    confirmOrderAlert: "Confirmar pedido?",
    listOfOrders: "Lista de Pedidos",
    itemsTag: "Itens",
    forDeliveryTag: "para entrega",
    product: "Produto",
    quantity: "Quantidade",
    price: "Preço",
    subtotal: "Subtotal",
    total: "Total",
    thereAreNoOrders: "Não há pedidos registrados.",
    noProductSelectedAlert: "Atenção: Nenhum produto foi selecionado!",
    couldNotFinishOrderAlert: "Erro: Não foi possível finalizar o pedido.",
  },
  deliveriesPage: {
    heading: "Entregas",
    paidTag: "Pago",
    notPaidTag: "Não Pago",
    deliveredTag: "Entregue",
    notDeliveredTag: "Não Entregue",
    items: "Itens",
    product: "Produto",
    quantity: "Quantidade",
    price: "Preço",
    deliveryAddress: "Endereço",
    clientName: "Nome do Cliente",
    clientPhone: "Telefone do Cliente",
    orderDelivered: "Pedido Entregue?",
    orderDeliveredYes: "Sim",
    orderDeliveredNo: "Não",
    thereAreNoDeliveries: "Não há entregas registradas.",
  },
  deliveryMapPage: {
    paidTag: "Pago",
    notPaidTag: "Não Pago",
    deliveredTag: "Entregue",
    notDeliveredTag: "Não Entregue",
    callClientButton: "Ligar para cliente",
    product: "Produto",
    price: "Preço",
    quantity: "Quantidade",
    subtotal: "Subtotal",
    total: "Total",
    client: "Cliente",
    phone: "Telefone",
    details: "Detalhes",
  },
  adminSummary: {
    deliveriesPending: "Entregas Pendentes",
    deliveriesCompleted: "Entregas Completas",
    orders: "Pedidos",
    preorders: "Encomendas",
  },
};
