export const englishLanguage: ILanguage = {
  navbar: {
    products: "Products",
    orders: "Orders",
    deliveries: "Deliveries",
    deliverymap: "Delivery Map",
    signout: "Sign Out",
  },
  productsPage: {
    heading: "Products",
    createProduct: "Create Product",
    newProductName: "Product Name",
    newProductDescription: "Product Description",
    newProductPrice: "Product Price",
    newProductImage: "Product Image URL",
    createProductButton: "Create Product",
    productsList: "Products List",
    numberOfProducts: "Number of Products",
    productImage: "Image",
    productName: "Name",
    productPrice: "Price",
    productOptions: "Options",
    editButton: "Edit",
    saveButton: "Save",
    cancelButton: "Cancel",
    thereAreNoProducts: "There are no products registered.",
    productCreatedAlert: (product: string) => `Product ${product} created.`,
    productEditedAlert: "The product information was saved.",
  },
  ordersPage: {
    heading: "Orders",
    createOrder: "Create Order",
    chooseAProduct: "Choose a product",
    orderItems: "Order Items",
    forDeliveryButton: "For Delivery",
    deliveryDetails: "Delivery Details",
    clientName: "Client Name",
    clientPhone: "Client Phone Number",
    deliveryAddress: "Delivery Address",
    orderAlreadyPaid: "Order already paid",
    confirmOrderAlert: "Confirm order?",
    listOfOrders: "List of Orders",
    itemsTag: "Items",
    forDeliveryTag: "for delivery",
    product: "Product",
    quantity: "Quantity",
    price: "Price",
    subtotal: "Subtotal",
    total: "Total",
    thereAreNoOrders: "There are no orders registered.",
    noProductSelectedAlert: "Attention: No product selected!",
    couldNotFinishOrderAlert: "Error: Could not finish order.",
  },
  deliveriesPage: {
    heading: "Deliveries",
    paidTag: "Paid",
    notPaidTag: "Not Paid",
    deliveredTag: "Delivered",
    notDeliveredTag: "Not Delivered",
    items: "Items",
    product: "Product",
    quantity: "Quantity",
    price: "Price",
    deliveryAddress: "Address",
    clientName: "Client Name",
    clientPhone: "Client Phone",
    orderDelivered: "Order Delivered?",
    orderDeliveredYes: "Yes",
    orderDeliveredNo: "No",
    thereAreNoDeliveries: "There are no deliveries registered.",
  },
  deliveryMapPage: {
    paidTag: "Paid",
    notPaidTag: "Not Paid",
    deliveredTag: "Delivered",
    notDeliveredTag: "Not Delivered",
    callClientButton: "Call client",
    product: "Product",
    price: "Price",
    quantity: "Quantity",
    subtotal: "Subtotal",
    total: "Total",
    client: "Client",
    phone: "Phone",
    details: "Details",
  },
  adminSummary: {
    deliveriesPending: "Deliveries Pending",
    deliveriesCompleted: "Deliveries Completed",
    orders: "Orders",
    preorders: "Preorders",
  },
};
