import { Heading } from "@aprendaagora/simple-react-component-library";
import React, { useContext } from "react";
import {
  FaCheckCircle,
  FaCheckSquare,
  FaTimesCircle,
  FaShoppingCart,
  FaCalendarAlt,
} from "react-icons/fa";
import { MdDeliveryDining } from "react-icons/md";
import { Link } from "react-router-dom";
import { LanguageContext } from "../contexts/LanguageContext";

const AdminScreen = () => {
  const language = useContext(LanguageContext).adminSummary;
  return (
    <div className="mx-5 mt-4 border border-slate-300 bg-white p-5 sm:mx-auto sm:w-[500px]">
      <Heading text="Admin Summary" className="mb-4 text-center" level={5} />

      <div>
        <Heading text="Today" level={6} className="mb-4" />

        <Link to="/admin/deliveries">
          <div className="admin-summary__option">
            <span className="flex w-12 items-center">
              <FaTimesCircle color="red" />
              <MdDeliveryDining className="ml-1" color="red" />
            </span>
            <span>{language.deliveriesPending}</span>
          </div>
        </Link>

        <Link to="/admin/deliveries">
          <div className="admin-summary__option">
            <span className="flex w-12 items-center">
              <FaCheckCircle color="green" />
              <MdDeliveryDining className="ml-1" color="green" />
            </span>
            <span>{language.deliveriesCompleted}</span>
          </div>
        </Link>

        <Link to="/admin/orders">
          <div className="admin-summary__option">
            <FaShoppingCart className="w-12" />
            <span>{language.orders}</span>
          </div>
        </Link>

        <Link to="/admin/orders">
          <div className="admin-summary__option">
            <FaCalendarAlt className="w-12" />
            <span>{language.preorders}</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AdminScreen;
